import React from "react";

export default function Header({ sections }) {
  return (
    <div className="h-[82px]">
      <div className="border-b-solid fixed z-[9999] h-[82px] w-full border-b-[1px] border-b-[#00000025] bg-white shadow-md">
        <div className="container mx-auto flex h-full w-full items-center justify-between">
          <img
            src={"cricketone_logo.png"}
            alt={"logo"}
            className="ml-5 h-[52px] w-[111px] cursor-pointer"
          />
          <div className="hidden flex-1 justify-center gap-[8px] text-[14px] sm:flex sm:gap-[20px] md:gap-[32px] md:text-[16px]">
            {Object.entries(sections)?.map(
              ([_, sec]) =>
                sec?.showNav && (
                  <a
                    className="font-medium text-black no-underline"
                    href={"/#"}
                    rel="noreferrer"
                    onClick={(e) => {
                      e.preventDefault();

                      const element = document.getElementById(sec.key);

                      if (element) {
                        const elementPosition =
                          element.getBoundingClientRect().top +
                          window.pageYOffset;
                        const offsetPosition = elementPosition - 60;

                        window.scrollTo({
                          top: offsetPosition,
                          behavior: "smooth", // Optional: for smooth scrolling
                        });
                      }
                    }}
                  >
                    {sec.navTitle}
                  </a>
                ),
            )}
          </div>
          <button className="mr-5 flex cursor-pointer items-center gap-[12px] !rounded-button border-none bg-primary-color p-[10px] text-[13px] font-semibold leading-[24px] text-white md:px-[16px] md:py-[10px] xl:text-[16px]">
            <img src={"phone.png"} width={20} height={20} alt={"phone"} />
            <span className="">
              <span className="hidden xl:inline">Whatsapp us:</span> +84
              937031500
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
