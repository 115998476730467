import React from "react";

export default function Rules({ section }) {
  return (
    <div className="mx-[20px] py-[64px]" id={section?.key}>
      <h2 className="m-0 p-0 text-center text-[24px] font-bold uppercase leading-[30px] text-primary-color lg:text-[40px] lg:leading-[48px]">
        {section?.title}
      </h2>
      <p className="mx-auto mb-[20px] max-w-[1082px] text-center text-[16px] lg:text-[20px] lg:leading-[30px]">
        {section?.description}
      </p>
      <div className="container mx-auto flex max-w-[1200px] flex-col gap-[25px]">
        <div className="bg-[#E6223D]">
          <div className="flex flex-col gap-[4px] md:gap-[20px] lg:flex-row">
            <div className="grid place-items-center">
              <img
                className="mt-[10px] w-[125px] border-0 border-b-[2px] border-solid !border-b-white lg:mt-0 lg:!border-r-[2px] lg:border-b-0 lg:!border-r-white"
                src={"rule_1.jpg"}
                alt={"rule_1"}
              />
            </div>
            <div className="px-[10px] py-[16px] font-medium text-white ">
              <div>{section?.blockOneDescription}</div>
            </div>
          </div>
        </div>

        <div className="bg-[#DEA73A]">
          <div className="flex flex-col gap-[4px] md:gap-[20px] lg:flex-row">
            <div className="grid place-items-center">
              <img
                className="mt-[10px] w-[125px] border-0 border-b-[2px] border-solid !border-b-white lg:mt-0 lg:!border-r-[2px] lg:border-b-0 lg:!border-r-white"
                src={"rule_2.jpg"}
                alt={"rule_2"}
              />
            </div>
            <div className="px-[10px] py-[16px] font-medium text-white ">
              {section?.blockTwoDescription}
            </div>
          </div>
        </div>

        <div className="bg-[#C08E2D] ">
          <div className="flex flex-col gap-[4px] md:gap-[20px] lg:flex-row">
            <div className="grid place-items-center">
              <img
                className="mt-[10px] w-[125px] border-0 border-b-[2px] border-solid !border-b-white lg:mt-0 lg:!border-r-[2px] lg:border-b-0 lg:!border-r-white"
                src={"rule_3.jpg"}
                alt={"rule_3"}
              />
            </div>
            <div className={"px-[10px] py-[16px] font-medium text-white "}>
              {section?.blockThreeDescription}
            </div>
          </div>
        </div>

        <div className="bg-[#3F7F45]">
          <div className="flex flex-col gap-[4px] md:gap-[20px] lg:flex-row">
            <div className="grid place-items-center">
              <img
                className="mt-[10px] w-[125px] border-0 border-b-[2px] border-solid !border-b-white lg:mt-0 lg:!border-r-[2px] lg:border-b-0 lg:!border-r-white"
                src={"rule_4.jpg"}
                alt={"rule_4"}
              />
            </div>
            <div className={"px-[10px] py-[16px] font-medium text-white "}>
              {section?.blockFourDescription}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
