import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/axios";
import { defaultLocale } from "../../utils/locale";
import { getApiUrl } from "../../constants";

export default function ProductFootprint({ section }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`/product-footprints?locale=${defaultLocale}&populate[0]=fileUrl`)
      .then((res) => {
        setData(
          res.data?.data?.map(({ id, attributes: { skuName, fileUrl } }) => ({
            id,
            skuName,
            fileUrl: `${getApiUrl().replace("/api", "")}${fileUrl?.data?.attributes?.url}`,
          })),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const selectedFileUrl = data?.find((item) => +item.id === +selected)?.fileUrl;

  return (
    <div className="bg-[#FCFFF4] py-[32px] lg:py-[64px]" id={section?.key}>
      <div className="container mx-auto max-w-[1200px]">
        <div className="mx-[16px] xl:mx-0">
          <h2 className="text-center text-[24px] font-bold leading-[30px] text-[#545454] lg:text-[30px] lg:leading-[36px]">
            {section?.title}
          </h2>

          <p className="mx-auto mt-[32px] text-justify text-[16px] lg:text-[20px] lg:leading-[30px]">
            {section?.description}
          </p>
          <div className="my-[32px] flex gap-[32px]">
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="mb-[30px] text-[24px] font-bold leading-[32px] lg:text-[33px] lg:leading-[40px]">
                    {section?.selectorTitle}
                  </span>
                </div>
                <div className="flex gap-[20px] lg:gap-[98px]">
                  <select
                    value={selected}
                    className="select select-bordered w-[200px] lg:w-[400px] lg:!pl-[44px]"
                    onChange={(e) => {
                      setSelected(e.target.value);
                    }}
                  >
                    <option value="">Select an option</option>
                    {data?.map(({ id, skuName }) => (
                      <option key={id} value={id}>
                        {skuName}
                      </option>
                    ))}
                  </select>
                  <div>
                    {selectedFileUrl && (
                      <a
                        href={selectedFileUrl}
                        target="_blank"
                        rel={"noreferrer"}
                        className="font-semibold decoration-transparent lg:ml-[10px] lg:text-[20px]"
                      >
                        <button className="btn btn-primary ml-0 border-none !text-[16px] !leading-[24px] !text-white max-[768px]:!px-2 lg:!pr-[10px]">
                          DOWNLOAD
                          <div className="mx-[1px] h-full w-[1px] bg-white lg:mx-[10px]" />
                          <img
                            src={"download.png"}
                            alt={"download"}
                            className="h-[16px] w-[16px] lg:mr-[10px] lg:h-[23px] lg:w-[25px]"
                          />
                        </button>
                      </a>
                    )}
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* <div className="flex flex-col items-center">
{loading && (
  <span className="loading-2xl loading loading-ring text-primary" />
)}
{!loading && (
  <div>
    <table
      className={`
    [&_:is(td,th)]:p-unset
table table-xs table-fixed border-collapse border border-solid text-center md:table-md lg:text-[12px] [&_:is(td,th)]:max-[1025px]:p-[2px] [&_:is(td,th)]:max-[1025px]:text-[6px] [&_:is(td,th,tr)]:text-center [&_td]:border [&_td]:border-solid [&_th]:border
[&_th]:border-solid
`}
    >
      <thead>
        <tr>
          <th className="font-bold text-black"></th>
          <th
            colSpan={4}
            className="bg-red-300 font-bold text-black"
          >
            Water
          </th>
          <th
            colSpan={2}
            className="bg-orange-200 font-bold text-black"
          >
            GHG
          </th>
          <th
            colSpan={4}
            className="bg-blue-200 font-bold text-black"
          >
            Energy (MJ/kg)
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="font-bold text-black">SKU</td>
          <td className="bg-red-300 font-bold text-black">
            Drinking
          </td>
          <td className="bg-red-300 font-bold text-black">
            Cleaning
          </td>
          <td className="bg-red-300 font-bold text-black">
            Processing
          </td>
          <td className="bg-red-300 font-bold text-black">
            Factory sanitation
          </td>
          <td className="bg-orange-200 font-bold text-black">
            CH4 (ppm)
          </td>
          <td className="bg-orange-200 font-bold text-black">
            CO2 (ppb)
          </td>
          <td className="bg-blue-200 font-bold text-black">
            Washing
          </td>
          <td className="bg-blue-200 font-bold text-black">
            Boiling
          </td>
          <td className="bg-blue-200 font-bold text-black">
            Drying
          </td>
          <td className="bg-blue-200 font-bold text-black">
            Defeating
          </td>
        </tr>
        {data.map((item) => {
          return (
            <tr key={item.id}>
              <th className="">{item.name}</th>
              <td className="">{item.waterDrinking}</td>
              <td className="">{item.waterCleaning}</td>
              <td className="">{item.waterProcessing}</td>
              <td className="">{item.waterFactorySanitation}</td>
              <td className="">{item.ghgCh4}</td>
              <td className="">{item.ghgCo2}</td>
              <td className="">{item.energyWashing}</td>
              <td className="">{item.energyBoiling}</td>
              <td className="">{item.energyDrying}</td>
              <td className="">{item.energyDefeating}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
)}
</div> */
}
