import dayjs from "dayjs";

export const parseSafe = (str) => {
  try {
    return JSON.parse(str);
  } catch (_) {
    return undefined;
  }
};

export function sortDataByDate(dataArray) {
  return dataArray.sort((a, b) => {
    // Create Day.js objects for comparison
    let dateA = dayjs(`${a.year}-${a.month}`);
    let dateB = dayjs(`${b.year}-${b.month}`);

    // Compare the dates
    if (dateA.isBefore(dateB)) {
      return -1; // a comes first
    } else if (dateA.isAfter(dateB)) {
      return 1; // b comes first
    } else {
      return 0; // Dates are the same
    }
  });
}
