import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { sortDataByDate } from "../../utils/helper";
import { isNil, takeRight } from "lodash";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1000,
        callback: function (value) {
          return value;
        },
      },
    },
  },
};

export function ElectricityChartByKg({ section, location }) {
  const monthlyResources = location?.monthly_resources?.filter((i) =>
    ["month", "year", "solarUsed", "gridUsed"].every(
      (field) => !isNil(i[field]),
    ),
  );

  const sortedData = sortDataByDate(monthlyResources);

  const data = {
    labels: [
      null,
      ...takeRight(
        sortedData.map((item) =>
          dayjs()
            .set("year", item.year)
            .set("month", item.month - 1)
            .format("MM/YYYY"),
        ),
        12,
      ),
      null,
    ],
    datasets: [
      {
        label: "Solar (kwh/kg)",
        data: [
          null,
          ...sortedData.map((item) =>
            (item.solarUsed / (item.kgUsed || 1)).toFixed(1),
          ),
          null,
        ],
        borderColor: "rgb(249, 115, 22)",
        backgroundColor: "rgb(249, 115, 22, 0.5)",
      },
      {
        label: "Grid (kwh/kg)",
        data: [
          null,
          ...sortedData.map((item) =>
            (item.gridUsed / (item.kgUsed || 1)).toFixed(1),
          ),
          null,
        ],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgb(53,162,235,0.5)",
      },
    ],
  };

  return (
    <div className="text-center">
      <Line data={data} options={options} />
      <div className="mx-[20px] mt-[16px] inline-block rounded-[6px] bg-primary px-[20px] py-[6px] font-medium text-white md:mt-[32px] lg:px-[54px] lg:py-[10px] lg:text-[20px]">
        {section?.electricityConsumptionTrackingByKilogramDescription}
      </div>
    </div>
  );
}
