import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { parseSafe } from "../../utils/helper";
import dayjs from "dayjs";
import { uniqBy } from "lodash";
import axiosInstance from "../../utils/axios";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

export function CO2Chart({ section, mode, data, recentData, device }) {
  const [CO2Data, setCO2Data] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deviceUsageData, setDeviceUsageData] = useState([]);
  const isShortRange = mode === "1h";
  const isLongRange = !isShortRange;

  useEffect(() => {
    setLoading(true);

    if (device?.key && !isShortRange) {
      const getFilterByMode = {
        "24h": {
          pageSize: 24,
          timeRange: "hourly",
        },
        "31d": {
          pageSize: 31,
          timeRange: "daily",
        },
        "6m": {
          pageSize: 6,
          timeRange: "monthly",
        },
      };

      const filter = getFilterByMode?.[mode];

      axiosInstance
        .get(
          `/usages?populate[0]=device_key&sort=timestamp:desc&filters[device_key][key][$eq]=${device.key}&filters[timeRange][$eq]=${filter.timeRange}&pagination[pageSize]=${filter.pageSize}`,
        )
        .then((res) =>
          setDeviceUsageData(
            res.data?.data
              ?.reverse()
              ?.map(
                ({
                  id,
                  attributes: {
                    payload,
                    changes,
                    timeRange,
                    device_key,
                    timestamp,
                  },
                }) => ({
                  id,
                  payload,
                  changes,
                  timeRange,
                  device_key: device_key?.data?.attributes?.key,
                  timestamp: +timestamp,
                }),
              ),
          ),
        )
        .finally(() => {
          setLoading(false);
        });
    }
  }, [device, mode]);

  useEffect(() => {
    if (Array.isArray(recentData)) {
      const formattedRecentData = [
        ...recentData
          .map((item) => parseSafe(item?.payload)?.data)
          .filter((i) => i)
          .map((item) => ({
            ...item,
            timestamp: item.timestamp * 1000,
          })),
      ].filter((i) => !isNaN(i?.co2));

      setCO2Data((prev) => [...formattedRecentData, ...prev]);
    }
  }, [recentData, setCO2Data]);

  useEffect(() => {
    if (data) {
      const payload = parseSafe(data)?.state?.reported;
      setCO2Data((prev) => [
        ...prev,
        { ...payload, timestamp: payload.timestamp * 1000 },
      ]);
    }
  }, [data, setCO2Data]);

  const renderShortRangeChart = () => {
    const shortRangeChartData = {
      labels: uniqBy(CO2Data, "timestamp")
        ?.filter(
          (i) => i && dayjs(i?.timestamp).isAfter(dayjs().subtract(1, "hours")),
        )
        ?.map((item) => dayjs(item?.timestamp).format("HH:mm")),
      datasets: [
        {
          label: "CO2 (PPM)",
          data: uniqBy(CO2Data, "timestamp")
            ?.filter(
              (i) =>
                i && dayjs(i?.timestamp).isAfter(dayjs().subtract(1, "hours")),
            )
            ?.map((item) => +item?.co2),
          borderColor: "rgb(249, 115, 22)",
          backgroundColor: "rgb(249, 115, 22, 0.5)",
        },
      ],
    };

    const shortRangeChartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          min: Math.max(Math.min(...CO2Data.map((item) => +item?.co2)) - 20, 0),
          max: Math.max(...CO2Data.map((item) => +item?.co2)) + 20,
          beginAtZero: true,
          ticks: {
            callback: function (value) {
              return value + " PPM";
            },
          },
        },
      },
    };

    return (
      <div>
        <Line data={shortRangeChartData} options={shortRangeChartOptions} />
        <div className="mx-[20px] mt-[16px] inline-block rounded-[6px] bg-primary px-[20px] py-[6px] font-medium text-white md:mt-[32px] lg:px-[54px] lg:py-[10px] lg:text-[20px]">
          {section?.co2TrackingDescription}
        </div>
      </div>
    );
  };

  const renderLongRangeChart = () => {
    const longRangeChartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        tooltip: {
          callbacks: {
            title: function (tooltipItems) {
              return tooltipItems[0].label;
            },
            label: function (context) {
              const key = context.dataset.key;

              const payload = parseSafe(
                deviceUsageData?.[context.dataIndex]?.changes,
              );

              const reportedAt =
                key === "max"
                  ? payload?.["max_co2_at"]
                  : payload?.["min_co2_at"];

              return `${context.dataset.label}: ${context.parsed.y} at ${dayjs(reportedAt * 1000).format("HH:mm")}`;
            },
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          min: Math.max(
            Math.min(
              ...deviceUsageData?.map(
                (item) => parseSafe(item?.changes)?.min_co2,
              ),
            ) - 20,
            0,
          ),
          max:
            Math.max(
              ...deviceUsageData?.map(
                (item) => parseSafe(item?.changes)?.max_co2,
              ),
            ) + 20,
          beginAtZero: true,
          ticks: {
            callback: function (value) {
              return value + " PPM";
            },
          },
        },
      },
    };

    const longRangeChartData = {
      labels: deviceUsageData?.map((item) =>
        dayjs(item.timestamp)
          .subtract(
            1,
            {
              "24h": "hour",
              "31d": "day",
              "6m": "month",
            }?.[mode],
          )
          .format(
            {
              "24h": "HH:mm",
              "31d": "DD/MM",
              "6m": "MM/YYYY",
            }?.[mode],
          ),
      ),
      datasets: [
        {
          key: "max",
          label: "Max CO2 (PPM)",
          data: deviceUsageData?.map(
            (item) => parseSafe(item?.changes)?.max_co2,
          ),
          fill: "+1",
          borderColor: "#E6223D90",
          backgroundColor: "#DEA73A20",
          lineTension: 0.2,
        },
        {
          key: "min",
          label: "Min CO2 (PPM)",
          data: deviceUsageData?.map(
            (item) => parseSafe(item?.changes)?.min_co2,
          ),
          fill: false,
          borderColor: "#3F7F4590",
          lineTension: 0.2,
        },
      ],
    };

    return (
      <div>
        <Line data={longRangeChartData} options={longRangeChartOptions} roun />
        <div className="mx-[20px] mt-[16px] inline-block rounded-[6px] bg-primary px-[20px] py-[6px] font-medium text-white md:mt-[32px] lg:px-[54px] lg:py-[10px] lg:text-[20px]">
          {section?.co2TrackingDescription}{" "}
          {
            {
              "24h": "the last 24 hours",
              "31d": "the last month",
              "6m": "the last 6 months",
            }[mode]
          }
        </div>
      </div>
    );
  };

  return (
    <div className="text-center">
      <h2 className="m-0 mx-2 mt-5 inline-flex items-center gap-2 p-0 text-center text-[24px] font-bold uppercase leading-[30px] text-primary-color lg:text-[40px] lg:leading-[48px] xl:mx-0">
        <img src={"gas_icon.png"} alt={"gas_icon"} width={56} height={56} />{" "}
        {section?.gasTrackingTitle}
      </h2>
      <p className="mb-[48px] text-[16px] lg:mb-[100px] lg:text-[20px] lg:leading-[30px]">
        {section?.gasTrackingDescription}
      </p>
      {isShortRange && renderShortRangeChart()}
      {isLongRange && renderLongRangeChart()}
    </div>
  );
}

// import React, { useEffect, useState } from "react";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   Filler,
// } from "chart.js";
// import { Line } from "react-chartjs-2";
// import { parseSafe } from "../../utils/helper";
// import dayjs from "dayjs";
// import { uniqBy } from "lodash";
// import axiosInstance from "../../utils/axios";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   Filler,
// );

// export function CO2Chart({ mode, data, recentData, device }) {
//   const [CO2Data, setCO2Data] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [deviceUsageData, setDeviceUsageData] = useState([]);
//   const isShortRange = mode === "1h";
//   const isMediumRange = mode === "24h";
//   const isLongRange = !isShortRange && !isMediumRange;

//   useEffect(() => {
//     setLoading(true);

//     if (device?.key && !isShortRange) {
//       const getFilterByMode = {
//         "24h": {
//           pageSize: 24,
//           timeRange: "hourly",
//         },
//         "31d": {
//           pageSize: 31,
//           timeRange: "daily",
//         },
//         "6m": {
//           pageSize: 6,
//           timeRange: "monthly",
//         },
//       };

//       const filter = getFilterByMode?.[mode];

//       axiosInstance
//         .get(
//           `/usages?populate[0]=device_key&sort=timestamp:desc&filters[device_key][key][$eq]=${device.key}&filters[timeRange][$eq]=${filter.timeRange}&pagination[pageSize]=${filter.pageSize}`,
//         )
//         .then((res) =>
//           setDeviceUsageData(
//             res.data?.data
//               ?.reverse()
//               ?.map(
//                 ({
//                   id,
//                   attributes: {
//                     payload,
//                     changes,
//                     timeRange,
//                     device_key,
//                     timestamp,
//                   },
//                 }) => ({
//                   id,
//                   payload,
//                   changes,
//                   timeRange,
//                   device_key: device_key?.data?.attributes?.key,
//                   timestamp: +timestamp,
//                 }),
//               ),
//           ),
//         )
//         .finally(() => {
//           setLoading(false);
//         });
//     }
//   }, [device, mode]);

//   useEffect(() => {
//     if (Array.isArray(recentData)) {
//       const formattedRecentData = [
//         ...recentData
//           .map((item) => parseSafe(item?.payload)?.data)
//           .filter((i) => i)
//           .map((item) => ({
//             ...item,
//             timestamp: item.timestamp * 1000,
//           })),
//       ].filter((i) => !isNaN(i?.co2));

//       setCO2Data((prev) => [...formattedRecentData, ...prev]);
//     }
//   }, [recentData, setCO2Data]);

//   useEffect(() => {
//     if (data) {
//       const payload = parseSafe(data)?.state?.reported;
//       setCO2Data((prev) => [
//         ...prev,
//         { ...payload, timestamp: payload.timestamp * 1000 },
//       ]);
//     }
//   }, [data, setCO2Data]);

//   const renderShortRangeChart = () => {
//     const shortRangeChartData = {
//       labels: uniqBy(CO2Data, "timestamp")
//         ?.filter(
//           (i) => i && dayjs(i?.timestamp).isAfter(dayjs().subtract(1, "hours")),
//         )
//         ?.map((item) => dayjs(item?.timestamp).format("HH:mm")),
//       datasets: [
//         {
//           label: "CO2 (PPM)",
//           data: uniqBy(CO2Data, "timestamp")
//             ?.filter(
//               (i) =>
//                 i && dayjs(i?.timestamp).isAfter(dayjs().subtract(1, "hours")),
//             )
//             ?.map((item) => +item?.co2),
//           borderColor: "rgb(249, 115, 22)",
//           backgroundColor: "rgb(249, 115, 22, 0.5)",
//         },
//       ],
//     };

//     const shortRangeChartOptions = {
//       responsive: true,
//       plugins: {
//         legend: {
//           position: "top",
//         },
//       },
//       scales: {
//         x: {
//           grid: {
//             display: false,
//           },
//         },
//         y: {
//           min: Math.max(Math.min(...CO2Data.map((item) => +item?.co2)) - 20, 0),
//           max: Math.max(...CO2Data.map((item) => +item?.co2)) + 20,
//           beginAtZero: true,
//           ticks: {
//             callback: function (value) {
//               return value + " PPM";
//             },
//           },
//         },
//       },
//     };

//     return (
//       <div>
//         <Line data={shortRangeChartData} options={shortRangeChartOptions} />
//         <div className="mt-[16px] inline-block bg-primary px-[20px] py-[6px] rounded font-medium text-white md:mt-[32px] lg:px-[54px] lg:py-[10px] lg:text-[20px]">
//           CO2 levels are monitored in real-time
//         </div>
//       </div>
//     );
//   };

//   const renderMediumRangeChart = () => {
//     const allTimestamp = deviceUsageData.reduce((acc, item) => {
//       const payload = parseSafe(item?.changes);
//       const timestamps = [payload.min_co2_at, payload.max_co2_at].filter(
//         (i) => i,
//       );

//       acc = acc.concat(
//         timestamps.length > 0 ? timestamps : [item.timestamp / 1000],
//       );

//       return acc;
//     }, []);

//     const mediumRangeChartOptions = {
//       responsive: true,
//       plugins: {
//         legend: {
//           position: "top",
//         },
//         tooltip: {
//           callbacks: {
//             title: function (tooltipItems) {
//               return tooltipItems[0].label;
//             },
//             label: function (context) {
//               const key = context.dataset.key;

//               const payload = parseSafe(
//                 deviceUsageData?.[context.dataIndex]?.changes,
//               );

//               const reportedAt =
//                 key === "max"
//                   ? payload?.["max_co2_at"]
//                   : payload?.["min_co2_at"];

//               return `${context.dataset.label}: ${context.parsed.y} at ${dayjs(reportedAt * 1000).format("HH:mm")}`;
//             },
//           },
//         },
//       },
//       scales: {
//         x: {
//           grid: {
//             display: false,
//           },
//         },
//         y: {
//           min: Math.max(
//             Math.min(
//               ...deviceUsageData?.map(
//                 (item) => parseSafe(item?.changes)?.min_co2,
//               ),
//             ) - 20,
//             0,
//           ),
//           max:
//             Math.max(
//               ...deviceUsageData?.map(
//                 (item) => parseSafe(item?.changes)?.max_co2,
//               ),
//             ) + 20,
//           beginAtZero: true,
//           ticks: {
//             callback: function (value) {
//               return value + " PPM";
//             },
//           },
//         },
//       },
//     };

//     const mediumRangeChartData = {
//       labels: allTimestamp?.map((timestamp) =>
//         dayjs(timestamp * 1000)
//           .subtract(1, "hour")
//           .format("HH:mm"),
//       ),
//       datasets: [
//         {
//           key: "max",
//           label: "Max CO2 (PPM)",
//           data: allTimestamp.map((timestamp) => {
//             const match = deviceUsageData?.find(
//               (item) => parseSafe(item?.changes)?.max_co2_at === timestamp,
//             );

//             return parseSafe(match?.changes)?.max_co2 || undefined;
//           }),
//           fill: "+1",
//           borderColor: "#E6223D90",
//           backgroundColor: "#DEA73A20",
//         },
//         {
//           key: "min",
//           label: "Min CO2 (PPM)",
//           data: allTimestamp.map((timestamp) => {
//             const match = deviceUsageData?.find(
//               (item) => parseSafe(item?.changes)?.min_co2_at === timestamp,
//             );

//             return parseSafe(match?.changes)?.min_co2 || undefined;
//           }),
//           fill: false,
//           borderColor: "#3F7F4590",
//         },
//       ],
//     };

//     return (
//       <div>
//         <Line data={mediumRangeChartData} options={mediumRangeChartOptions} />
//         <div className="mt-[16px] inline-block bg-primary px-[20px] py-[6px] rounded font-medium text-white md:mt-[32px] lg:px-[54px] lg:py-[10px] lg:text-[20px]">
//           CO2 levels are monitored in the last 24 hours
//         </div>
//       </div>
//     );
//   };

//   const renderLongRangeChart = () => {
//     const longRangeChartOptions = {
//       responsive: true,
//       plugins: {
//         legend: {
//           position: "top",
//         },
//         tooltip: {
//           callbacks: {
//             title: function (tooltipItems) {
//               return tooltipItems[0].label;
//             },
//             label: function (context) {
//               const key = context.dataset.key;

//               const payload = parseSafe(
//                 deviceUsageData?.[context.dataIndex]?.changes,
//               );

//               const reportedAt =
//                 key === "max"
//                   ? payload?.["max_co2_at"]
//                   : payload?.["min_co2_at"];

//               return `${context.dataset.label}: ${context.parsed.y} at ${dayjs(reportedAt * 1000).format("HH:mm")}`;
//             },
//           },
//         },
//       },
//       scales: {
//         x: {
//           grid: {
//             display: false,
//           },
//         },
//         y: {
//           min: Math.max(
//             Math.min(
//               ...deviceUsageData?.map(
//                 (item) => parseSafe(item?.changes)?.min_co2,
//               ),
//             ) - 20,
//             0,
//           ),
//           max:
//             Math.max(
//               ...deviceUsageData?.map(
//                 (item) => parseSafe(item?.changes)?.max_co2,
//               ),
//             ) + 20,
//           beginAtZero: true,
//           ticks: {
//             callback: function (value) {
//               return value + " PPM";
//             },
//           },
//         },
//       },
//     };

//     const longRangeChartData = {
//       labels: deviceUsageData?.map((item) =>
//         dayjs(item.timestamp)
//           .subtract(
//             1,
//             {
//               "24h": "hour",
//               "31d": "day",
//               "6m": "month",
//             }?.[mode],
//           )
//           .format(
//             {
//               "24h": "HH:mm",
//               "31d": "DD/MM",
//               "6m": "MM/YYYY",
//             }?.[mode],
//           ),
//       ),
//       datasets: [
//         {
//           key: "max",
//           label: "Max CO2 (PPM)",
//           data: deviceUsageData?.map(
//             (item) => parseSafe(item?.changes)?.max_co2,
//           ),
//           fill: "+1",
//           borderColor: "#E6223D90",
//           backgroundColor: "#DEA73A20",
//           lineTension: 0.2,
//         },
//         {
//           key: "min",
//           label: "Min CO2 (PPM)",
//           data: deviceUsageData?.map(
//             (item) => parseSafe(item?.changes)?.min_co2,
//           ),
//           fill: false,
//           borderColor: "#3F7F4590",
//           lineTension: 0.2,
//         },
//       ],
//     };

//     return (
//       <div>
//         <Line data={longRangeChartData} options={longRangeChartOptions} roun />
//         <div className="mt-[16px] inline-block bg-primary px-[20px] py-[6px] rounded font-medium text-white md:mt-[32px] lg:px-[54px] lg:py-[10px] lg:text-[20px]">
//           CO2 levels are monitored in{" "}
//           {
//             {
//               "31d": "the last month",
//               "6m": "the last 6 months",
//             }[mode]
//           }
//         </div>
//       </div>
//     );
//   };

//   return (
//     <div className="text-center">
//       <h2 className="m-0 mx-2 mt-5 inline-flex items-center gap-2 p-0 text-center text-[24px] font-bold uppercase leading-[30px] text-primary-color xl:mx-0 lg:text-[40px] lg:leading-[48px]">
//         <img src={"gas_icon.png"} alt={"gas_icon"} width={56} height={56} />{" "}
//         Greenhouse Gases Emission
//       </h2>
//       <p className="mb-[48px] text-[16px] lg:mb-[100px] lg:text-[20px] lg:leading-[30px]">
//         CO2 and methane levels are monitored in real-time by IoT devices
//         installed within the farm
//       </p>
//       {isShortRange && renderShortRangeChart()}
//       {isMediumRange && renderMediumRangeChart()}
//       {isLongRange && renderLongRangeChart()}
//     </div>
//   );
// }
