import { useEffect, useState } from "react";
import Header from "./components/molecules/Header";
import Footer from "./components/molecules/Footer";
import MightyRevolution from "./components/molecules/MightyRevolution";
import ProductFootprint from "./components/molecules/ProductFootprint";
import Rules from "./components/molecules/Rules";
import Strategy from "./components/molecules/Strategy";
import Tracking from "./components/molecules/Tracking";
import "daisyui/dist/styled.css";
import axiosInstance from "./utils/axios";
import { defaultLocale } from "./utils/locale";
import Highlights from "./components/molecules/Highlights";
import { initializeGoogleAnalytics } from "./utils/analytics";

function App() {
  const [loadingSection, setLoadingSection] = useState(false);
  const [sections, setSections] = useState([]);

  const initialData = async () => {
    setLoadingSection(true);
    const sectionsApi = {
      overview: "/overview-section",
      footprint: "/footprint-section",
      commitments: "/sustainability-commitments-section",
      highlights: "/highlights-section",
      tracking: "/tracking-section",
      sdgsCommitments: "/sdgs-commitments-section",
    };

    const sectionsObj = {};

    for (const key in sectionsApi) {
      const response = await axiosInstance.get(
        `${sectionsApi[key]}?locale=${defaultLocale}`,
      );
      const { id, attributes } = response.data?.data || {};
      sectionsObj[key] = {
        id,
        ...attributes,
      };
    }

    setSections(sectionsObj);
  };

  useEffect(() => {
    initialData();
    initializeGoogleAnalytics();
  }, []);

  if (window.location.host === "esg.dtrack.asia") {
    return (
      <>
        <div className="container mx-auto mt-[20px] flex justify-center gap-[64px]">
          <img
            src={"dtrack_logo.jpg"}
            alt={"dtrack_logo"}
            className="h-[50px]"
          />
          <span className="inline-flex items-center gap-[6px]">
            <span className="text-[12px] font-bold">Powered by</span>
            <img
              src={"demeter_logo.png"}
              alt={"demeter_logo"}
              className="h-[50px]"
            />
          </span>
        </div>
        <div className="mt-[20px] text-center">
          <img src={"under_c.jpg"} alt={"under_c"} className="h-[600px]" />
        </div>
      </>
    );
  }

  return (
    <div className="">
      <Header sections={sections} />
      <MightyRevolution
        sectionKey={"overview"}
        section={sections?.["overview"]}
      />
      <ProductFootprint
        sectionKey={"footprint"}
        section={sections?.["footprint"]}
      />
      <Strategy
        sectionKey={"commitments"}
        section={sections?.["commitments"]}
      />
      <Highlights
        sectionKey={"highlights"}
        section={sections?.["highlights"]}
      />
      <Tracking sectionKey={"tracking"} section={sections?.["tracking"]} />
      <Rules
        sectionKey={"sdgsCommitments"}
        section={sections?.["sdgsCommitments"]}
      />
      <Footer />
    </div>
  );
}

export default App;
