import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";
import { getDemeterApi } from "../constants";

const useSocket = (thingName, emitOnMount = false) => {
  const socketRef = useRef(null);
  const [data, setData] = useState(null);
  const [recentData, setRecentData] = useState([]);

  useEffect(() => {
    const socket = socketIOClient(`${getDemeterApi()}/thing`);
    socketRef.current = socket;

    socket.on("connect", () => {
      if (emitOnMount) {
        socket.emit("requestState", { thingName });
      }

      socket.emit("requestRecentState", { thingName, value: 1, unit: "day" });

      socket.on(`${thingName}/get`, (data) => {
        setData(data);
      });
      socket.on(`${thingName}/recent-data`, (recentData) => {
        setRecentData(recentData);
      });
    });

    return () => {
      socket.disconnect();
    };
  }, [thingName]);

  return { data, recentData };
};

export default useSocket;
