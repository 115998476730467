import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/axios";
import { defaultLocale } from "../../utils/locale";

export default function MightyRevolution({ section }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`/benchmarks?locale=${defaultLocale}`)
      .then((res) =>
        setData(
          res.data?.data?.map(
            ({
              id,
              attributes: {
                name,
                energyUse,
                waterUsed,
                co2Emission,
                ch4Emission,
              },
            }) => ({
              id,
              name,
              energyUse,
              waterUsed,
              co2Emission,
              ch4Emission,
            }),
          ),
        ),
      )
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="mx-[16px] mb-[62px] pt-[45px] xl:mx-0" id={section?.key}>
      <div className="container mx-auto max-w-[1200px]">
        <h2 className="text-center text-[24px] font-bold leading-[30px] text-primary-color lg:text-[40px] lg:leading-[48px]">
          {section?.title}
        </h2>
        <p className="mx-auto mt-[32px] max-w-[1200px] text-justify text-[18px] md:text-[20px] md:leading-[30px]">
          {section?.description}
        </p>
        <div className="h-[32px]" />
        <h3 className="mx-auto max-w-[683px] text-center text-[20px] font-medium leading-[26px] lg:text-[26px] lg:leading-[30px]">
          {section?.tableTitle}
        </h3>
        <div className="flex min-h-[150px] flex-col items-center justify-center">
          {loading && (
            <span className="loading-2xl loading loading-ring text-primary" />
          )}
          {!loading && (
            <table
              className={`table table-xs !max-w-[752px] table-fixed border-collapse gap-0
            text-center lg:!max-w-[912px] [&_:is(td,th,tr)]:border-solid [&_:is(td,th,tr)]:border-gray-300 [&_:is(td,th,tr)]:p-2 md:[&_:is(td,th,tr)]:p-4 [&_th]:text-[7px] sm:[&_th]:text-[16px] lg:[&_th]:text-[16px]`}
            >
              {/* head */}
              <thead className="bg-primary-color">
                <tr>
                  <th className="font-bold text-white"></th>
                  <th className="font-bold text-white">Energy use (kW)</th>
                  <th className="font-bold text-white">Water Use (l)</th>
                  <th className="font-bold text-white">CO2 Emission (Kg)</th>
                  <th className="font-bold text-white">CH4 Emission (Kg)</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td className={``}>{item.name}</td>
                      <td className={``}>{item.energyUse}</td>
                      <td className={``}>{item.waterUsed}</td>
                      <td className={``}>{item.co2Emission}</td>
                      <td className={``}>{item.ch4Emission}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}
