export const getApiUrl = () => {
  const nodeEnv = process.env.NODE_ENV || "production";

  return {
    development: "https://esg-admin.cricketone.asia/api",
    production: "https://esg-admin.cricketone.asia/api",
  }[nodeEnv];

  // return {
  //   development: "http://localhost:1337/api",
  //   production: "https://esg-admin.cricketone.asia/api",
  // }[nodeEnv];
};

export const getDemeterApi = () => {
  const nodeEnv = process.env.NODE_ENV || "production";

  return {
    development: "https://stage-api.demeter.vn",
    production: "https://stage-api.demeter.vn",
  }[nodeEnv];

  // return {
  //   development: "http://localhost:3004",
  //   production: "https://stage-api.demeter.vn",
  // }[nodeEnv];
};

export const getAccessToken = () => {
  const nodeEnv = process.env.NODE_ENV || "production";

  return {
    development:
      "eb2a1f4da0183fe17e0d07c65e91319ebab165c9f046f264d7982f7322869b905544a8cf64ae3043aa8dd2383f1ac5ca8e0d5094cdec6808a9625d95c1e612db8349efb4f5fdf7a17d4b98dadc9150cf5e2649700cd03bbe68c2b4d5639ed67a85cf10570e250cdbbee287df0b748d88e4bc3d07807e72ae2a1e4796d55e9767",
    production:
      "eb2a1f4da0183fe17e0d07c65e91319ebab165c9f046f264d7982f7322869b905544a8cf64ae3043aa8dd2383f1ac5ca8e0d5094cdec6808a9625d95c1e612db8349efb4f5fdf7a17d4b98dadc9150cf5e2649700cd03bbe68c2b4d5639ed67a85cf10570e250cdbbee287df0b748d88e4bc3d07807e72ae2a1e4796d55e9767",
  }[nodeEnv];

  // return {
  //   development:
  //     "f993f2804faf9bc950493f6c4b1448ba3f8fffb741ac128694b29fa2690f35c16d35adda8b461221e17be1e1c4fbf44dceec55a9feb35a2002ce192f1355a8931461fa7b3b7ecbe40ad41da7484a5b34919a24086a2c801954d2764ab16abac2149f5fa008cad483ad1d95dddd6b25f292a625521f2b8a033ea9d1261662470c",
  //   production:
  //     "aaae76111c171d138dede58695ec35212a340a2f26282bbc25c241fdef85fd172429a63bcf333340c36e5f57a8b495f476c75bada5e99f5756d656809f37b561801ee6b5f58c8d22fcbea8f8e3d81d8d01d94e983b9453f95dd483633516ef6fa66da2814c28a124eef659f1d0144404f9e510722c8d709a33440235170908e7",
  // }[nodeEnv];
};

export const WATER_CHART_RANGE = {
  last6Hours: "Last 6 hours",
  lastWeek: "Last week",
  last6Months: "Last 6 months",
};
