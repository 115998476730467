import React, { useEffect, useState } from "react";
import { WaterChart } from "../atoms/WaterChart";
import { WaterChartByKg } from "../atoms/WaterChartByKg";
import { CO2Chart } from "../atoms/CO2Chart";
import { ElectricityChart } from "../atoms/ElectricityChart";
import { ElectricityChartByKg } from "../atoms/ElectricityChartByKg";
import { ElectricityPieChart } from "../atoms/ElectricityPieChart";
import axiosInstance from "../../utils/axios";
import { defaultLocale } from "../../utils/locale";
import { CH4Chart } from "../atoms/CH4Chart";
import useSocket from "../../hooks/useSocket";
import { WATER_CHART_RANGE } from "../../constants";

export default function Tracking({ section }) {
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [locations, setLocations] = useState({});
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const selectedLocation = locations?.[selectedLocationId] || null;
  const [gasMode, setGasMode] = useState("1h");

  const device = selectedLocation?.devices?.[0] || {};
  const { data, recentData } = useSocket(device?.key) || {};

  const [waterMode, setWaterMode] = useState("lastWeek");

  useEffect(() => {
    setLoadingLocation(true);

    axiosInstance
      .get(
        `/locations?locale=${defaultLocale}&populate[0]=devices&populate[1]=monthly_resources`,
      )
      .then((res) => {
        setLocations(
          res.data?.data?.reduce((acc, { id, attributes }, index) => {
            acc[id] = {
              ...attributes,
              id,
              devices: attributes?.devices?.data?.map((device) => ({
                id: device?.id,
                ...device?.attributes,
              })),
              monthly_resources: attributes?.monthly_resources?.data?.map(
                (monthly) => ({
                  id: monthly?.id,
                  ...monthly?.attributes,
                }),
              ),
            };

            if (attributes?.name === "Farm") {
              setSelectedLocationId(id);
            }

            return acc;
          }, {}),
        );
      })
      .finally(() => {
        setLoadingLocation(false);
      });
  }, []);

  return (
    <div className="py-[32px] lg:py-[64px]" id={section?.key}>
      <div className="container mx-auto max-w-[1200px]">
        <div className="mx-[16px] xl:mx-0">
          <h2 className="text-center text-[24px] font-bold leading-[30px] text-primary-color lg:text-[40px] lg:leading-[48px]">
            {section?.title}
          </h2>
          <p className="text-center text-[16px] lg:text-[20px] lg:leading-[30px]">
            {section?.description}
          </p>
          <div className="my-[32px] flex gap-[32px]">
            {loadingLocation && (
              <div className="grid place-items-center">
                <span className="loading loading-spinner loading-lg" />
              </div>
            )}
            <div>
              <label className="form-control w-full max-w-xs">
                <div className="label">
                  <span className="text-[20px] font-bold lg:text-[33px]">
                    Location
                  </span>
                </div>
              </label>
              <div className="flex gap-[20px]">
                <select
                  value={selectedLocationId}
                  onChange={(e) => setSelectedLocationId(e.target.value)}
                  className="select select-bordered select-lg w-[200px] lg:w-[400px] lg:!pl-[44px]"
                >
                  {Object.values(locations).map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </select>
                {/* <button className="btn btn-primary border-none text-white max-[768px]:!px-2 md:pr-[10px]">
                  <a
                    href={
                      "https://storage.googleapis.com/demeter-staging/uploads/sample_pdf.pdf"
                    }
                    target="_blank"
                    rel={"noreferrer"}
                    className="ml-0 text-[16px] font-semibold leading-[24px] text-white decoration-transparent lg:ml-[10px] lg:text-[20px]"
                  >
                    DOWNLOAD REPORT
                  </a>
                  <div className="mx-[1px] h-full w-[1px] bg-white lg:mx-[10px]" />
                  <img
                    src={"download.png"}
                    alt={"download"}
                    className="h-[16px] w-[16px] lg:mr-[10px] lg:h-[23px] lg:w-[25px]"
                  />
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div key={selectedLocationId}>
        {device?.functions?.includes?.("water") && (
          <>
            <div className="bg-[#FCFFF4]">
              <div className="container mx-auto max-w-[1200px]">
                <div className="mx-[16px] xl:mx-0">
                  <select
                    value={waterMode}
                    onChange={(e) => setWaterMode(e.target.value)}
                    className="select select-bordered my-[16px] w-[200px]"
                  >
                    {Object.entries(WATER_CHART_RANGE).map(([value, label]) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                  <WaterChart
                    location={selectedLocation}
                    device={device}
                    mode={waterMode}
                    section={section}
                  />
                  <WaterChartByKg
                    location={selectedLocation}
                    device={device}
                    mode={waterMode}
                    section={section}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {(device?.functions?.includes?.("co2") ||
          device?.functions?.includes?.("ch4")) && (
          <>
            <div className="h-[48px] lg:h-[100px]" />
            <div className="container mx-auto max-w-[1200px]">
              <div className="mx-[16px] mb-[32px] xl:mx-0">
                <div>
                  <div className="label-text text-[20px] font-bold">Range</div>
                  <select
                    value={gasMode}
                    onChange={(e) => setGasMode(e.target.value)}
                    className="select select-bordered mt-[8px] w-[200px]"
                  >
                    {[
                      { value: "1h", name: "Last hour" },
                      { value: "24h", name: "A day ago" },
                      { value: "31d", name: "31 days ago" },
                      { value: "6m", name: "6 months ago" },
                    ].map(({ value, name }) => (
                      <option key={value} value={value}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
                {device?.functions?.includes?.("co2") && (
                  <CO2Chart
                    location={selectedLocation}
                    data={data}
                    recentData={recentData}
                    mode={gasMode}
                    device={device}
                    section={section}
                  />
                )}
                {device?.functions?.includes?.("ch4") && (
                  <CH4Chart
                    location={selectedLocation}
                    data={data}
                    recentData={recentData}
                    mode={gasMode}
                    device={device}
                    section={section}
                  />
                )}
              </div>
            </div>
          </>
        )}
        {device?.functions?.includes?.("electricity") && (
          <>
            <div className="bg-[#FCFFF4] py-[32px] lg:py-[64px]">
              <div className="container mx-auto max-w-[1200px]">
                <div className="mx-[16px] xl:mx-0">
                  <ElectricityChart
                    location={selectedLocation}
                    section={section}
                  />
                </div>
              </div>
              <div className="h-[48px] lg:h-[100px]" />
              <div className="container mx-auto max-w-[1200px]">
                <div className="mx-[16px] xl:mx-0">
                  <ElectricityChartByKg
                    location={selectedLocation}
                    section={section}
                  />
                </div>
              </div>
            </div>
            <div className="h-[48px] lg:h-[100px]" />
            <div className="container mx-auto max-w-[1200px]">
              <div className="mx-[16px] xl:mx-0">
                <ElectricityPieChart
                  location={selectedLocation}
                  section={section}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
