import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { isNil } from "lodash";

ChartJS.register(ArcElement, Tooltip, Legend);

export function ElectricityPieChart({ section, location }) {
  const monthlyResources = location?.monthly_resources?.filter((i) =>
    ["month", "year", "solarUsed", "gridUsed"].every(
      (field) => !isNil(i[field]),
    ),
  );

  const totalSolidUsed = monthlyResources?.reduce((acc, item) => {
    acc = acc + (+item.solarUsed || 0);
    return acc;
  }, 0);

  const totalgridUsed = monthlyResources?.reduce((acc, item) => {
    acc = acc + (+item.gridUsed || 0);
    return acc;
  }, 0);

  const total = totalgridUsed + totalSolidUsed;

  const data = {
    labels: ["Solar", "Grid"],
    datasets: [
      {
        data: [
          +((totalSolidUsed * 100) / total).toFixed(1),
          +((totalgridUsed * 100) / total).toFixed(1),
        ],
        backgroundColor: ["#029147", "#E87205"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div>
      <div className="flex flex-col items-center gap-[32px] md:flex-row md:justify-around md:gap-0">
        <div className="flex flex-col justify-between gap-[32px] lg:flex-row">
          <Pie
            data={data}
            plugins={[ChartDataLabels]}
            options={{
              plugins: {
                legend: {
                  display: false, // This hides all text in the legend and also the labels.
                },
                datalabels: {
                  display: true,
                  color: "white",
                  font: {
                    size: "16",
                    weight: 500,
                  },
                  formatter: (value) => `${value}%`,
                },
              },
            }}
            className="max-h-[400px] max-w-[400px]"
          />
          <div>
            <div className="flex items-center gap-3 text-[22px] font-semibold max-[1021px]:justify-center">
              <span className="inline-block h-[8px] w-[8px] bg-primary-color" />
              <span>Solar (kwh/kg)</span>
            </div>
            <div className="mt-[10px] flex items-center gap-3 text-[22px] font-semibold max-[1021px]:justify-center">
              <span className="inline-block h-[8px] w-[8px] bg-[#E87205]" />
              <span>Grid (kwh/kg)</span>
            </div>
          </div>
        </div>
        <div className="flex h-[448px] w-[338px] flex-col justify-between rounded-2xl shadow-2xl drop-shadow">
          <div className="flex h-[81px] items-center justify-center rounded-tl-2xl rounded-tr-2xl bg-primary-color text-center text-[22px] font-bold leading-[29px] text-white">
            TỔNG LƯỢNG ĐIỆN TIÊU THỤ
          </div>
          <div className="mt-[12px] flex flex-1 flex-col items-center">
            <img
              src={"electricity.png"}
              alt={"electricity"}
              width={75}
              height={90}
            />
            <div className="mt-[20px]">
              <div className="text-[24px] font-semibold leading-[60px] text-[#E87205]">
                <span className="inline-block h-[22px] w-[22px] bg-[#E87205]" />{" "}
                Solar: {totalSolidUsed} kwh
              </div>
              <div className="text-[24px] font-semibold leading-[60px] text-primary-color">
                <span className="inline-block h-[22px] w-[22px] bg-primary-color" />{" "}
                Grid: {totalgridUsed} kwh
              </div>
            </div>
          </div>
          <div className="h-[22px] rounded-bl-2xl rounded-br-2xl bg-primary-color" />
        </div>
      </div>
      <div className="mt-[16px] text-center md:mt-[32px]">
        <div className="inline-block bg-primary px-[20px] py-[6px] font-medium text-white md:mt-[32px] lg:px-[54px] lg:py-[10px] lg:text-[20px]">
          {section?.totalElectricityTrackingDescription}
        </div>
      </div>
    </div>
  );
}
