export const initializeGoogleAnalytics = () => {
  if (!window.dataLayer) {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-ZLVNMLWJ7C";
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-ZLVNMLWJ7C");
  }
};
