import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { uniqBy } from "lodash";
import { parseSafe } from "../../utils/helper";
import dayjs from "dayjs";
import axiosInstance from "../../utils/axios";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

export function CH4Chart({ section, mode, device, data, recentData }) {
  const [CH4Data, setCH4Data] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deviceUsageData, setDeviceUsageData] = useState([]);
  const isShortRange = mode === "1h";

  useEffect(() => {
    setLoading(true);

    if (device?.key && !isShortRange) {
      const getFilterByMode = {
        "24h": {
          pageSize: 24,
          timeRange: "hourly",
        },
        "31d": {
          pageSize: 31,
          timeRange: "daily",
        },
        "6m": {
          pageSize: 6,
          timeRange: "monthly",
        },
      };

      const filter = getFilterByMode?.[mode];

      axiosInstance
        .get(
          `/usages?populate[0]=device_key&sort=timestamp:desc&filters[device_key][key][$eq]=${device.key}&filters[timeRange][$eq]=${filter.timeRange}&pagination[pageSize]=${filter.pageSize}`,
        )
        .then((res) =>
          setDeviceUsageData(
            res.data?.data
              ?.reverse()
              ?.map(
                ({
                  id,
                  attributes: {
                    payload,
                    changes,
                    timeRange,
                    device_key,
                    timestamp,
                  },
                }) => ({
                  id,
                  payload,
                  changes,
                  timeRange,
                  device_key: device_key?.data?.attributes?.key,
                  timestamp: +timestamp,
                }),
              ),
          ),
        )
        .finally(() => {
          setLoading(false);
        });
    }
  }, [device, mode]);

  useEffect(() => {
    if (Array.isArray(recentData)) {
      const formattedRecentData = [
        ...recentData
          .map((item) => parseSafe(item?.payload)?.data)
          .filter((i) => i)
          .map((item) => ({
            ...item,
            timestamp: item.timestamp * 1000,
          })),
      ].filter((i) => !isNaN(i?.ch4));

      setCH4Data((prev) => [...formattedRecentData, ...prev]);
    }
  }, [recentData, setCH4Data]);

  useEffect(() => {
    if (data) {
      const payload = parseSafe(data)?.state?.reported;

      setCH4Data((prev) => [
        ...prev,
        { ...payload, timestamp: payload.timestamp * 1000 },
      ]);
    }
  }, [data, setCH4Data]);

  const shortRangeChartData = {
    labels: uniqBy(CH4Data, "timestamp")
      ?.filter(
        (i) => i && dayjs(i?.timestamp).isAfter(dayjs().subtract(1, "hours")),
      )
      ?.map((item) => dayjs(item?.timestamp).format("HH:mm")),
    datasets: [
      {
        label: "CH4 (%LEL)",
        data: uniqBy(CH4Data, "timestamp")
          ?.filter(
            (i) =>
              i && dayjs(i?.timestamp).isAfter(dayjs().subtract(1, "hours")),
          )
          ?.map((item) => +item?.ch4),
        borderColor: "rgb(249, 115, 22)",
        backgroundColor: "rgb(249, 115, 22, 0.5)",
      },
    ],
  };

  const shortRangeChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        min: Math.max(Math.min(...CH4Data.map((item) => +item?.ch4)) - 5, 0),
        max: Math.min(Math.max(...CH4Data.map((item) => +item?.ch4)) + 5, 100),
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + " %LEL";
          },
        },
      },
    },
  };

  const longRangeChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        min: Math.max(
          Math.min(
            ...deviceUsageData?.map(
              (item) => parseSafe(item?.changes)?.min_ch4,
            ),
          ) - 5,
          0,
        ),
        max:
          Math.max(
            ...deviceUsageData?.map(
              (item) => parseSafe(item?.changes)?.max_ch4,
            ),
          ) + 5,
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + " %LEL";
          },
        },
      },
    },
  };

  const longRangeChartData = {
    labels: deviceUsageData?.map((item) =>
      dayjs(item.timestamp)
        .subtract(
          1,
          {
            "24h": "hour",
            "31d": "day",
            "6m": "month",
          }?.[mode],
        )
        .format(
          {
            "24h": "HH:mm",
            "31d": "DD/MM",
            "6m": "MM/YYYY",
          }?.[mode],
        ),
    ),
    datasets: [
      {
        label: "Max CH4 (%LEL)",
        data: deviceUsageData?.map((item) => parseSafe(item?.changes)?.max_ch4),
        fill: "+1",
        borderColor: "rgb(232, 114, 5, 0.7)",
        backgroundColor: "rgb(232, 114, 5, 0.2)",
      },
      {
        label: "Min CH4 (%LEL)",
        data: deviceUsageData?.map((item) => parseSafe(item?.changes)?.min_ch4),
        fill: false,
        borderColor: "rgb(232, 114, 5, 0.5)",
      },
    ],
  };

  return (
    <div className="mt-[100px] text-center">
      {isShortRange && (
        <div>
          {CH4Data?.length > 0 && (
            <Line data={shortRangeChartData} options={shortRangeChartOptions} />
          )}
          <div className="mx-[20px] mt-[16px] inline-block rounded-[6px] bg-primary px-[20px] py-[6px] font-medium text-white md:mt-[32px] lg:px-[54px] lg:py-[10px] lg:text-[20px]">
            {section?.ch4TrackingDescription} real-time
          </div>
        </div>
      )}
      {!isShortRange && (
        <div>
          <Line data={longRangeChartData} options={longRangeChartOptions} />
          <div className="mx-[20px] mt-[16px] inline-block rounded-[6px] bg-primary px-[20px] py-[6px] font-medium text-white md:mt-[32px] lg:px-[54px] lg:py-[10px] lg:text-[20px]">
            {section?.ch4TrackingDescription}
            {
              {
                "24h": "the last day",
                "31d": "the last 31 days",
                "6m": "the last 6 months",
              }[mode]
            }
          </div>
        </div>
      )}
    </div>
  );
}
