import React, { useState, useEffect } from "react";

// Debounce function
function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

// Custom hook to get the current screen width with debounce
export default function useScreenWidth(debounceTime = 100) {
  // Default debounce time is 100ms
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Handler to call on window resize with debounce
    const handleResize = debounce(() => {
      // Set window width to state
      setScreenWidth(window.innerWidth);
    }, debounceTime);

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [debounceTime]); // Effect dependencies list includes debounceTime to re-apply if it changes

  return screenWidth;
}
