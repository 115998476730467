import React from "react";
import { ReactComponent as Achievement } from "../atoms/Svg/achievement.svg";

export default function Highlights({ section }) {
  const blockOneListItems = section?.blockOneDescription?.split("\n") || [];
  const blockTwoListItems = section?.blockTwoDescription?.split("\n") || [];
  const blockThreeListItems = section?.blockThreeDescription?.split("\n") || [];

  return (
    <div className="bg-[#F8F8F8] py-[32px] lg:py-[64px]" id={section?.key}>
      <div className="container mx-auto max-w-[1200px]">
        <div className="mx-[16px] xl:mx-0">
          <h2 className="text-center text-[24px] font-bold uppercase leading-[30px] text-primary-color lg:text-[40px] lg:leading-[48px]">
            {section?.title}
          </h2>

          <p className="mx-auto text-justify text-[16px] lg:text-[20px] lg:leading-[30px]">
            {section?.description}
          </p>

          <div className="my-[46px] [&_li]:mt-[4px]">
            <div className="flex flex-col gap-[26px] lg:flex-row">
              <div className="rounded-[20px] bg-[#DFEBDC] p-[30px] shadow-lg drop-shadow">
                <div className="flex items-center justify-center gap-[20px]">
                  <img
                    src="checklist.png"
                    alt="box-2"
                    className="h-[40px] w-[40px]"
                  />
                </div>
                <p className="text-justify text-[16px] lg:text-[20px] lg:leading-[30px]">
                  <ul>
                    {blockOneListItems?.map((item, index) => (
                      <li key={`block_highlight_1_content_${index}`}>{item}</li>
                    ))}
                  </ul>
                </p>
              </div>
              <div className="rounded-[20px] bg-[#DFEBDC] p-[30px] shadow-lg drop-shadow">
                <div className="flex items-center justify-center gap-[20px]">
                  <img
                    src="checklist.png"
                    alt="box-2"
                    className="h-[40px] w-[40px]"
                  />
                </div>
                <p className="text-justify text-[16px] lg:text-[20px] lg:leading-[30px]">
                  <ul>
                    {blockTwoListItems?.map((item, index) => (
                      <li key={`block_highlight_1_content_${index}`}>{item}</li>
                    ))}
                  </ul>
                </p>
              </div>
            </div>
            <div className="mt-[33px] flex flex-col gap-[12px] rounded-[20px] bg-[#D9D9D9] p-[36px] shadow-lg drop-shadow lg:flex-row">
              <div className="text-center lg:w-[250px]">
                <Achievement className="w-[100px]" />
              </div>
              <p className="text-[16px] lg:text-justify lg:text-[20px] lg:leading-[30px]">
                <ul>
                  {blockThreeListItems?.map((item, index) => (
                    <li key={`block_highlight_1_content_${index}`}>{item}</li>
                  ))}
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
