import React, { useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import useScreenWidth from "../../hooks/useScreenWidth";
import axiosInstance from "../../utils/axios";
import { parseSafe } from "../../utils/helper";
import dayjs from "dayjs";
import numeral from "numeral";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const numberFormat = (value) => numeral(value).format("0.[000]");

export function WaterChart({ section, location, device, mode }) {
  const sw = useScreenWidth();
  const [deviceUsageData, setDeviceUsageData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (device?.key) {
      const getFilterByMode = {
        last6Hours: {
          pageSize: 6,
          timeRange: "hourly",
        },
        lastWeek: {
          pageSize: 7,
          timeRange: "daily",
        },
        last6Months: {
          pageSize: 6,
          timeRange: "monthly",
        },
      };

      const filter = getFilterByMode?.[mode];

      axiosInstance
        .get(
          `/usages?populate[0]=device_key&sort=timestamp:desc&filters[device_key][key][$eq]=${device.key}&filters[timeRange][$eq]=${filter.timeRange}&pagination[pageSize]=${filter.pageSize}`,
        )
        .then((res) =>
          setDeviceUsageData(
            res.data?.data
              ?.reverse()
              ?.map(
                ({
                  id,
                  attributes: {
                    payload,
                    changes,
                    timeRange,
                    device_key,
                    timestamp,
                  },
                }) => ({
                  id,
                  payload,
                  changes,
                  timeRange,
                  device_key: device_key?.data?.attributes?.key,
                  timestamp: +timestamp,
                }),
              ),
          ),
        )
        .finally(() => {
          setLoading(false);
        });
    }
  }, [device, mode]);

  const options = {
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        align: "end",
        anchor: "end",
        padding: {
          top: "0",
          right: "0",
          left: "0",
          bottom: "0",
        },
        font: { size: sw > 768 ? "14" : "8" },
      },
      legend: {
        position: "bottom",
      },
    },
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const chartData = useMemo(() => {
    const changesData = deviceUsageData?.map((item) =>
      parseSafe(item?.changes),
    );

    const getLabelByLocationName = {
      Farm: {
        total_flow_meter_01: "Feeding water (m3)",
        total_flow_meter_02: "Cleaning water (m3)",
      },
      Factory: {
        total_flow_meter_01: "Cleaning Water (m3)",
        total_flow_meter_02: "Washing Water (m3)",
      },
    };

    const labelByLocationName =
      getLabelByLocationName?.[location?.name] ||
      getLabelByLocationName["Factory"];

    return {
      labels: deviceUsageData?.map((item) =>
        dayjs(item?.timestamp)
          .subtract(
            1,
            {
              last6Hours: "hour",
              lastWeek: "day",
              last6Months: "month",
            }?.[mode],
          )
          .format(
            {
              last6Hours: "HH:mm",
              lastWeek: "DD/MM",
              last6Months: "MM/YYYY",
            }?.[mode],
          ),
      ),
      datasets: [
        {
          label: "Total (m3)",
          data: changesData.map((item) =>
            numberFormat(
              (item?.total_flow_meter_01 || 0) +
                (item?.total_flow_meter_02 || 0),
            ),
          ),
          backgroundColor: "rgb(249 115 22)",
          stack: "Total Stack",
        },
        {
          label: labelByLocationName.total_flow_meter_01,
          data: changesData.map((item) =>
            numberFormat(item?.total_flow_meter_01 || 0),
          ),
          backgroundColor: "rgb(6 182 212)",
          stack: "Feeding Stack",
        },
        {
          label: labelByLocationName.total_flow_meter_02,
          data: changesData.map((item) =>
            numberFormat(item?.total_flow_meter_02 || 0),
          ),
          backgroundColor: "rgb(53, 162, 235)",
          stack: "Cleaning Stack",
        },
      ],
    };
  }, [deviceUsageData, mode, location]);

  return (
    <div className="w-full pb-[32px] text-center lg:py-[64px]">
      <h2 className="text-center text-[24px] font-bold leading-[30px] text-primary-color lg:text-[40px] lg:leading-[48px]">
        {section?.waterTrackingTitle}
      </h2>
      <div className="border border-solid border-gray-300 p-[16px] sm:p-[32px] lg:p-[64px]">
        <Bar options={options} data={chartData} />
        <div className="mx-[20px] mt-[16px] inline-block rounded-[6px] bg-primary px-[20px] py-[6px] font-medium text-white md:mt-[32px] lg:px-[54px] lg:py-[10px] lg:text-[20px]">
          {section?.waterTrackingDescription} {location?.name}
        </div>
      </div>
    </div>
  );
}
