import React from "react";

export default function Strategy({ section }) {
  const blockOneListItems = section?.blockOneDescription?.split("\n") || [];
  const blockTwoListItems = section?.blockTwoDescription?.split("\n") || [];
  const blockThreeListItems = section?.blockThreeDescription?.split("\n") || [];

  return (
    <div className="bg-[#F8F8F8] py-[32px] lg:py-[64px]" id={section?.key}>
      <div className="container mx-auto max-w-[1200px]">
        <div className="mx-[16px] xl:mx-0">
          <h2 className="text-center text-[24px] font-bold uppercase leading-[30px] text-primary-color lg:text-[40px] lg:leading-[48px]">
            {section?.title}
          </h2>

          <p className="mx-auto text-justify text-[16px] lg:text-[20px] lg:leading-[30px]">
            {section?.description}
          </p>

          <div>
            <h3 className="m-0 mt-[32px] p-0 text-[24px] font-bold leading-[28px] lg:mt-[62px] lg:text-[35px] lg:leading-[42px]">
              {section?.goalTitle}
            </h3>
            <p className="mt-[15px] text-justify text-[16px] lg:text-[20px] lg:leading-[30px]">
              {section?.goalDescription}
            </p>
          </div>

          <div className="my-[46px] [&_li]:mt-[4px]">
            <div className="rounded-[20px] bg-[#DFEBDC] p-[30px] shadow-lg drop-shadow">
              <div className="flex items-center gap-[20px]">
                <img
                  src="strategy-box.png"
                  alt="box-2"
                  className="h-[40px] w-[40px]"
                />
                <span
                  className={
                    "text-[22px] font-medium leading-[26px] text-[#006735] lg:text-[35px] lg:leading-[42px]"
                  }
                >
                  {section?.blockOneTitle}
                </span>
              </div>
              <p className="text-[16px] lg:text-justify lg:text-[20px] lg:leading-[30px]">
                <ul className="pl-[16px]">
                  {blockOneListItems?.map((item, index) => (
                    <li key={`block_1_content_${index}`}>{item}</li>
                  ))}
                </ul>
              </p>
            </div>
            <div className="mt-[33px] rounded-[20px] bg-[#DFEBDC] p-[30px] shadow-lg drop-shadow">
              <div className="flex items-center gap-[20px]">
                <img
                  src="strategy-box.png"
                  alt="box-2"
                  className="h-[40px] w-[40px]"
                />
                <span
                  className={
                    "text-[22px] font-medium leading-[26px] text-[#006735] lg:text-[35px] lg:leading-[42px]"
                  }
                >
                  {section?.blockTwoTitle}
                </span>
              </div>
              <p className="text-[16px] lg:text-justify lg:text-[20px] lg:leading-[30px]">
                <ul className="pl-[16px]">
                  {blockTwoListItems?.map((item, index) => (
                    <li key={`block_2_content_${index}`}>{item}</li>
                  ))}
                </ul>
              </p>
            </div>
            <div className="mt-[33px] rounded-[20px] bg-[#DFEBDC] p-[30px] shadow-lg drop-shadow">
              <div className="flex items-center gap-[20px]">
                <img
                  src="strategy-box.png"
                  alt="box-2"
                  className="h-[40px] w-[40px]"
                />
                <span
                  className={
                    "text-[22px] font-medium leading-[26px] text-[#006735] lg:text-[35px] lg:leading-[42px]"
                  }
                >
                  {section?.blockThreeTitle}
                </span>
              </div>
              <p className="text-[16px] lg:text-justify lg:text-[20px] lg:leading-[30px]">
                <ul className="pl-[16px]">
                  {blockThreeListItems?.map((item, index) => (
                    <li key={`block_3_content_${index}`}>{item}</li>
                  ))}
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
