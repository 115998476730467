import React from "react";

export default function Footer() {
  return (
    <div className="h-[80px] lg:h-[135px]">
      <div className="border-b-solid z-[9999] h-[80px] w-full border-b-[#00000025]  bg-[#228F00] shadow-lg drop-shadow-2xl lg:h-[135px] [&_a]:text-white">
        <div className="container mx-auto flex h-full w-full max-w-[1200px] items-center justify-between">
          <div className="hidden flex-1 justify-center gap-[8px] text-[14px] sm:flex sm:gap-[20px] md:gap-[32px] md:text-[16px]"></div>
          <img
            src={"cricketone_logo.png"}
            alt={"logo"}
            className="ml-5 h-[55px] w-[111px] cursor-pointer sm:ml-0"
          />
          <div className="mx-[15px] items-center justify-end gap-[8px] text-[14px] sm:flex sm:flex-1 sm:gap-[20px] md:gap-[32px] md:text-[16px] xl:mx-0">
            <div className="mr-[10px] flex h-[55px] flex-col justify-between lg:mr-0">
              <div className="mx-[4px] text-center text-[11px] font-light tracking-wide">
                POWERED BY
              </div>
              <div className="my-[2px] h-[1px] bg-black xl:my-[4px]" />
              <div className="mx-[4px] flex justify-between gap-[10px]">
                <a
                  href={"https://dtrack.asia/"}
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <img
                    src={"dtrack_logo_2.jpg"}
                    alt={"dtrack_logo"}
                    className="h-[26px]"
                  />
                </a>
                <a
                  href={"https://demeter.vn/"}
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <img
                    src={"demeter_logo.png"}
                    alt={"demeter_logo"}
                    className="h-[26px]"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
